<template>
  <div :class="['sunrise-sunset-main']">
    <div v-if="!noData">
    <ReportWithSliderVue :numReports="numReports">
      <div :class="['tile is-parent is-5 p-0', reportFullscreen.status && 'display-contents']">
          <div class="report-outside" :class="[reportFullscreen.status && 'p-0']">
            <div class="report-inside transparent">
              <HorizontalDotPlot />
            </div>
          </div>
      </div>
      <div :class="['tile is-parent is-vertical is-7 p-0', reportFullscreen.status && 'display-contents']">
        <div class="report-outside" :class="[reportFullscreen.status && 'p-0']">
          <div class="featured-topics-Parent report-inside transparent">
            <Wordcloud
            :cloudId="'word-cloud1'"
            :wordData="featuredTopicsData"
            :chartTitle="chartTitle"
            :chartInfo="chartInfo"
            :type="chartType" />
          </div>
        </div>
        <div class="report-outside" :class="[reportFullscreen.status && 'p-0']">
          <div class="comments-share report-inside transparent">
            <HorizontalGroupedBarChart
              :chartData="sharePerProduct" />
          </div>
        </div>
      </div>
    </ReportWithSliderVue>
    </div>
    <div class="warning-tile" v-else>
    <no-data-container-vue type="sunset-sunrise" v-if="noData" />
    </div>
  </div>
</template>

<script>
import HorizontalDotPlot from '../../components/SunriseSunset/HorizontalDotPlot'
import Wordcloud from '../../components/SunriseSunset/WordCloud'
import HorizontalGroupedBarChart from '../../components/SunriseSunset/HorizontalGroupedBarChart.vue'
import ReportWithSliderVue from '../../components/Report/ReportWithSlider.vue'
import { mapActions, mapState } from 'vuex'
import NoDataContainerVue from '../../components/Common/NoDataContainer/NoDataContainer.vue'
import { metaTitle } from '../../constant/data'

/** This is the parent component/view for the **Trending and Declining Drivers** page. */
export default {
  name: 'SunriseSunset',
  metaInfo () {
    return {
      title: metaTitle.trends
    }
  },
  components: {
    HorizontalDotPlot,
    ReportWithSliderVue,
    Wordcloud,
    HorizontalGroupedBarChart,
    NoDataContainerVue
  },
  data () {
    return {
      noData: true,
      observer: null,
      numReports: 3,
      chartTitle: 'Featured Topics',
      chartInfo: 'Word size indicates the relative increase or decline in mentions of the corresponding topic',
      chartType: 'emerging'
    }
  },
  computed: {
    ...mapState('filters', [
      'selectedSourceList',
      'selectedCategories',
      'selectedProducts',
      'sortedProducts',
      'selectedIndustries',
      'selectedCompanies',
      'subCategories',
      'ActiveVocTimePeriod',
      'previousPeriodRange',
      'isPreviousPeriodInSubscription'
    ]),
    ...mapState('sunriseSunset', ['jsonData', 'sharePerProduct', 'featuredTopicsData', 'selectedSSTopic', 'allTopics']),
    ...mapState('common', ['isCollapsed', 'reportFullscreen', 'currentModule']),
    watchable () {
      return JSON.stringify(this.selectedSourceList) + JSON.stringify(this.selectedIndustries) + JSON.stringify(this.selectedCompanies) + JSON.stringify(this.selectedProducts) + JSON.stringify(this.previousPeriodRange) + JSON.stringify(this.isPreviousPeriodInSubscription)
    }
  },
  watch: {
    jsonData () {
      if (!this.jsonData.length) {
        this.noData = true
      } else {
        this.noData = false
      }
    },
    watchable () {
      if (this.watchable.length) {
        this.getData()
      }
    },
    // selectedSourceList () {
    //   this.getData()
    // },
    // selectedIndustries () {
    //   this.getData()
    // },
    // selectedCompanies () {
    //   this.getData()
    // },
    // selectedProducts () {
    //   this.getData()
    // },
    // previousPeriodRange () {
    //   this.getData()
    // },
    isPreviousPeriodInSubscription () {
      // console.log(this.isPreviousPeriodInSubscription)
      if (this.isPreviousPeriodInSubscription === false) {
        this.noData = true
      } else {
        this.noData = false // add this condition in watchable property
        // this.getData()
      }
    },
    selectedSSTopic () {
      this.getRelativeData()
    },
    allTopics () {
      this.getRelativeData()
    }
    // featuredTopicsData () {
    //   console.log('wordLenght:', this.featuredTopicsData.length)
    //   // this.wordData = new Array(this.featuredTopicsData)
    // }
  },
  created () {
    // this.getData()
  },
  mounted () {
    this.getData()
    // console.log(this.isPreviousPeriodInSubscription)
    if (this.isPreviousPeriodInSubscription === false) {
      this.noData = true
    } else {
      this.noData = false
    }
  },
  methods: {
    ...mapActions('sunriseSunset', [
      'getDotPlotData',
      'getSharePerProductData',
      'getFeaturedTopicsData',
      'updateSelectedSSTopic'
    ]),
    /**
     * This method gets the data for the charts on the Trending and Declining drivers page
     * @public
     */
    getData () {
      if (
        this.selectedSourceList.length &&
        this.selectedProducts.length &&
        this.selectedIndustries.length &&
        this.selectedCategories != null &&
        this.subCategories.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod &&
        this.previousPeriodRange.length &&
        this.isPreviousPeriodInSubscription
      ) {
        const data = {
          module: this.currentModule,
          source: this.selectedSourceList.map((item) => item.id),
          products: this.selectedProducts,
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          industries: this.selectedIndustries.map((item) => item.id),
          companySize: this.selectedCompanies.map((item) => item.id),
          categoryId: this.subCategories,
          subscriptionId: this.selectedCategories.subscriptionId,
          previousPeriodRange: this.previousPeriodRange
        }
        if (this.selectedSSTopic) {
          data.selectedSSTopic = this.selectedSSTopic
          // data.is_standard = this.is_SSTstandard
          data.is_standard = this.selectedSSTopic.is_standard
        }
        this.getDotPlotData(data)
        // this.getSharePerProductData(data)
        // this.getFeaturedTopicsData(data)
        // this.noData = false
      } else {
        this.noData = true
      }
      // this.checkDataSelection()
    },
    /**
     * This method gets the data for the previous time period
     * @public
     */
    getRelativeData () {
      if (
        this.selectedSourceList.length &&
        this.selectedProducts.length &&
        this.selectedIndustries.length &&
        this.selectedCategories != null &&
        this.subCategories.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod &&
        this.previousPeriodRange.length &&
        this.isPreviousPeriodInSubscription &&
        this.allTopics.length
      ) {
        const data = {
          module: this.currentModule,
          source: this.selectedSourceList.map((item) => item.id),
          products: this.selectedProducts,
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          industries: this.selectedIndustries.map((item) => item.id),
          companySize: this.selectedCompanies.map((item) => item.id),
          categoryId: this.subCategories,
          subscriptionId: this.selectedCategories.subscriptionId,
          previousPeriodRange: this.previousPeriodRange,
          allTopics: this.allTopics
        }
        if (this.selectedSSTopic) {
          data.selectedSSTopic = this.selectedSSTopic
          // data.is_standard = this.is_SSTstandard
          data.is_standard = this.selectedSSTopic.is_standard
        }
        // this.getDotPlotData(data)
        this.getSharePerProductData(data)
        this.getFeaturedTopicsData(data)
        // this.noData = false
      } else {
        this.noData = true
      }
      // this.checkDataSelection()
    }
  }
}
</script>

<style lang="scss" src="./SunriseSunset.scss" scoped />
